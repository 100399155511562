.home-page {
  background-color: #EEEEEE;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.home-page .logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.home-page .logo-section img {
  max-width: 200px;
}



.home-page {
  color: #333333;

}


.home-page .card {
  border-radius: 15px;
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.home-page .card .card-body {
  padding: 2em;
}

.custom-alert {
  background-color: #4caf50 !important;
  color: white !important;
  font-size: 1em;
  line-height: 1.5;
  text-align: justify;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@media screen and (min-width: 768px) {
  .app-content {
    max-width: 560px; /* ou a largura que você preferir */
    margin: 0 auto; /* centraliza o conteúdo */
  }
}

.custom-title {
  color: #4caf50;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

